export default {
  wrapper: {
    width: 'calc(100% + 0.5rem)',
    margin: '-0.25rem',
    flexWrap: 'wrap',
    lisStyle: 'none',
    padding: 0,
  },
  item: {
    flexBasis: '100%',
    padding: '0.25rem',
    '> div': {
      width: '100%',
    },
  },
  itemSmall: {
    variant: 'imagesGrid.item',
    flexBasis: ['100%', '50%', ''],
  },
};
