export default {
  width: '100%',
  variant: 'text.bodySmall',
  title: {
    marginBottom: '1rem',
    variant: 'text.h3',
  },
  subTitle: {
    variant: 'text.h5',
  },
  row: {
    width: '100%',
    minHeight: ['', '12rem', '16rem'],
    '&:not(:last-of-type)': {
      marginBottom: ['2.5rem', '6.75rem', '4rem'],
    },
  },
  a: {
    wordBreak: 'break-word',
    display: 'inline-block',
  },
  link: {
    display: 'inline-block',
    '&.zoom': {
      display: ['none', 'none', 'inline-block'],
    },
    '+ .phone': {
      display: ['', '', 'none'],
    },
    variant: 'links.underlinedInline',
  },
  grid: {
    columnCount: [1, 2, ''],
    columnGap: ['', '3.125rem', '2rem'],
    item: {
      minHeight: ['', '22rem', '24rem'],
      breakInside: 'avoid',
      pageBreakInside: 'avoid',
      '&:not(:last-of-type)': {
        paddingBottom: ['5rem', '1rem', ''],
        overflow: 'hidden',
      },
    },
  },
};
