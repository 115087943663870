import { useLayoutEffect, useState } from 'react';
import noop from 'lodash/noop';

const useIsRevealAnimationFinished = (
  selectorOrElement: string | Element | null,
): boolean => {
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);

  useLayoutEffect(() => {
    const elem =
      typeof selectorOrElement === 'string'
        ? document.querySelector(selectorOrElement)
        : selectorOrElement;

    if (!elem) {
      return noop;
    }

    const setIsAnimationEnd = (): void => {
      setIsAnimationFinished(true);
    };

    elem.addEventListener('animationend', setIsAnimationEnd, false);

    return (): void => {
      elem.removeEventListener('animationend', setIsAnimationEnd, false);
    };
  }, [selectorOrElement]);

  return isAnimationFinished;
};

export default useIsRevealAnimationFinished;
