export default {
  wrapper: {
    width: '100%',
    backgroundColor: 'accent',
    color: 'text',
    padding: ['1.375rem 0', '', '2.5rem 0'],
  },
  content: {
    padding: ['3.125rem 0', '3.25rem 0', '7rem 0'],
    maxWidth: ['', '', '80.75rem'],
  },
};
