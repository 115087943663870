import text from '../../../../../gatsby-plugin-theme-ui/text';

export default {
  heading: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '0.5rem',
    close: {
      variant: 'buttons.underlinedAccent',
      ...text.smallBody,
    },
  },
  content: {
    width: '100%',
    ...text.smallBody,
    h2: {
      ...text.h6,
      margin: 0,
    },
    p: {
      margin: 0,
    },
    '.hs-form': {
      margin: 0,
      width: '100%',
    },
    '.hs-form-field': {
      width: '100%',
      marginBottom: '2.5rem',
    },
    '.hs-richtext': {
      marginBottom: '1.875rem',
    },
    '.hs-form-field label': {
      color: 'text',
      ...text.smallBody,
    },
    '.input': {
      display: 'block',
    },
    '.hs-input[type="text"], .hs-input[type="number"], .hs-input[type="email"], .hs-input[type="tel"]': {
      width: '100%',
      boxSizing: 'border-box',
      margin: 0,
      backgroundColor: 'white',
      height: '2.5rem',
      border: 0,
      padding: '0 1.25rem',
      color: 'borderLight',
      outline: 'none',
      borderRadius: 0,
      WebkitAppearance: 'none',
      ...text.smallBody,
    },
    '.hs-submit': {
      marginTop: '2.5rem',
    },
    '.actions': {
      display: 'inline-flex',
      variant: 'links.underlinedAccent',
    },
    '.hs-button': {
      border: 0,
      padding: 0,
      backgroundColor: 'transparent',
      outline: 'none',
      cursor: 'pointer',
      ...text.smallBody,
    },
    '.inputs-list': {
      marginTop: '0.3125rem',
      listStyle: 'none',
      padding: 0,
      li: {
        padding: 0,
        '&::before': {
          display: 'none',
        },
        '&:not(:last-of-type)': {
          marginBottom: '0.3125rem',
        },
      },
    },
    '.hs-error-msgs li .hs-error-msg, .hs-error-msgs li .hs-main-font-element': {
      color: 'error',
      ...text.error,
    },
    '.hs-form-radio-display, .hs-form-booleancheckbox-display': {
      cursor: 'pointer',
      display: 'flex',
      position: 'relative',
      input: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        opacity: 0,
        height: 0,
        width: 0,
      },
      '> span': {
        display: 'block',
        paddingLeft: '2.25rem',
        position: 'relative',
        margin: 0,
        '&::before': {
          content: '""',
          position: 'absolute',
          height: '1.25rem',
          width: '1.25rem',
          backgroundColor: 'transparent',
          border: '1px solid #979797',
          boxShadow: '1px 1px 2px 0 rgba(0,0,0,0.5)',
          transition: '250ms ease-in-out',
          left: 0,
          top: '0.3125rem',
        },
      },
      'input:checked + span::before': {
        backgroundColor: 'accent',
      },
    },
    '.hs-form-radio-display span::before': {
      borderRadius: '100%',
    },
    '.hs-form-booleancheckbox-display span::before': {
      borderRadius: '2px',
    },
    '.legal-consent-container .hs-form-booleancheckbox-display > span': {
      margin: 0,
    },
  },
};
