import { useLocation } from '@reach/router';
import React from 'react';
import { Box, Container } from 'theme-ui';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import FooterCopy from './copy';
import { useFooterData } from './hooks';
import FooterInfo from './info';
import { FooterProps } from './types';

const Footer: React.FC<FooterProps> = ({ item, links }) => {
  const { infoItems } = useFooterData();
  const location = useLocation();

  return (
    <Box as="footer" variant="footer.wrapper">
      <RevealAnimationWrapper key={location.pathname}>
        <Container>
          <FooterInfo {...{ items: [item, ...infoItems] }} />
          <FooterCopy {...{ links }} />
        </Container>
      </RevealAnimationWrapper>
    </Box>
  );
};

export default Footer;
