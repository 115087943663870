export const bodySmall = {
  fontSize: ['1.25rem', '', '1.875rem'],
  lineHeight: [1.6, '', 1.3],
  fontWeight: 'regular',
  fontFamily: 'body',
};

export default {
  h0: {
    fontSize: ['3rem', '5.625rem', '6rem'],
    lineHeight: [1.1, 1, 1.1],
    fontWeight: 'black',
    fontFamily: 'heading',
  },
  h1: {
    fontSize: ['2.25rem', '2.625rem', '5rem'],
    lineHeight: [1.1, 1.24, 1],
    fontWeight: 'black',
    fontFamily: 'heading',
  },
  h2: {
    fontSize: ['2rem', '2.25rem', '3.25rem'],
    lineHeight: [1.25, 1.2, ''],
    fontWeight: 'black',
    fontFamily: 'heading',
  },
  h3: {
    fontSize: ['1.75rem', '1.875rem', '2.625rem'],
    lineHeight: [1.29, 1.27, 1.24],
    fontWeight: 'bold',
    fontFamily: 'body',
  },
  h4: {
    fontSize: ['1.625rem', '1.75rem', '2.25rem'],
    lineHeight: [1.3, 1.29, 1.3],
    fontFamily: 'heading',
  },
  h4roman: {
    variant: 'text.h4',
    fontWeight: 'regular',
  },
  h4black: {
    variant: 'text.h4',
    fontWeight: 'black',
  },
  h5: {
    fontSize: ['1.5rem', '1.75rem', '1.875rem'],
    lineHeight: [1.25, 1.29, 1.3],
    fontWeight: 'bold',
    fontFamily: 'body',
  },
  h6: {
    fontSize: '1.5rem',
    lineHeight: 1.25,
    fontWeight: 'bold',
    fontFamily: 'body',
  },
  tags: {
    fontSize: '1rem',
    lineHeight: 1.25,
    fontWeight: 'bold',
    fontFamily: 'body',
    textTransform: 'uppercase',
  },
  body: {
    fontSize: '1.875rem',
    lineHeight: 1.3,
    fontWeight: 'regular',
    fontFamily: 'body',
  },
  bodySmall,
  smallBody: {
    fontSize: '1.25rem',
    lineHeight: 1.6,
    fontWeight: 'regular',
    fontFamily: 'body',
  },
  menu: {
    fontSize: '1.125rem',
    lineHeight: 1,
    fontWeight: 'bold',
    fontFamily: 'body',
  },
  filter: {
    fontSize: ['1rem', '', '1.25rem'],
    lineHeight: [2, '', 1.6],
    fontWeight: 'regular',
    fontFamily: 'body',
  },
  accent: {
    font: 'inherit',
    backgroundImage:
      'linear-gradient(to right, rgba(255, 255, 255, 0) 50%, #F5FF00 50%)',
    backgroundSize: '200%',
    display: 'inline',
    padding: 0,
    margin: 0,
  },
  paragraph: {
    fontSize: ['1.25rem', '', '1.875rem'],
    lineHeight: [1.6, '', 1.3],
    fontWeight: 'regular',
    fontFamily: 'body',
  },
  error: {
    fontSize: '1rem',
    lineHeight: 1,
    fontWeight: 'regular',
    fontFamily: 'body',
  },
};
