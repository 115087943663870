export default {
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    width: '100%',
  },
  listItem: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: ['5rem', '', '10rem'],
    },
  },
};
