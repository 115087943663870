import { keyframes } from '@emotion/core';

export const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 10rem, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
`;

export const heroBannerScaleAnimation = keyframes`
	0% {
		transform: scaleY(.2)
	}
	to {
		transform: scaleY(1)
	}
`;

export const pageMarginTopAnimation = keyframes`
	0% {
    margin-top: calc(90vh - var(--header-height));
	}
	100% {
		margin-top: 0px;
	}
`;
