export default {
  wrapper: {
    display: 'flex',
    width: '100%',
    outline: 'none',
    color: 'white',
    position: 'relative',
    willChange: 'transform',
    '&:hover .overlay': {
      opacity: ['', 1, ''],
      visibility: ['', 'visible', ''],
    },
    '&:hover .thumbnail-tile-media': {
      transform: ['', 'scale(1.05)', ''],
    },
    '> div': {
      width: '100%',
    },
  },
  wrapperDark: {
    variant: 'thumbnailTile.wrapper',
    color: 'text',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    padding: ['0.5rem', '1rem', '2rem'],
    maxWidth: ['', '24rem', '40rem'],
    width: ['', '50%', ''],
    zIndex: 2,
    transition: '400ms ease-in-out',
  },
  contentSmall: {
    maxWidth: ['', '50%', ''],
    variant: 'thumbnailTile.content',
  },
  overlay: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0,
    visibility: 'hidden',
    transition: '400ms ease-in-out',
    icon: {
      fontSize: ['4rem', '5rem', '7.5rem'],
    },
  },
  media: {
    width: '100%',
    transition: '400ms ease-in-out',
  },
  link: {
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
};
