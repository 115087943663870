export default {
  wrapper: {
    padding: ['5rem 0 2.5rem', '', '10rem 0 5rem'],
    // transition: '250ms ease-in-out',
    '.is-animating &, .tl-wrapper-status--exited &, .tl-wrapper-status--exiting &': {
      opacity: 0,
    },
  },
  info: {
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', '1fr repeat(2, 24%)'],
    gridGap: ['2rem', '2.5rem 3.25rem', '2.5rem 0'],
    width: '100%',
    box: {
      width: '100%',
      paddingRight: ['', '', '20%'],
      '&:first-of-type': {
        gridColumn: ['', '1 / -1', '1 / 2'],
        maxWidth: ['', '', '40rem'],
      },
      title: {
        variant: 'text.h4black',
      },
      list: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        width: '100%',
      },
      listItem: {
        width: '100%',
        '&:not(:last-of-type)': {
          marginBottom: ['', '0.5rem', '1rem'],
        },
      },
      link: {
        variant: 'links.underlinedInline',
        wordBreak: 'break-word',
        display: 'inline-block',
      },
    },
  },
  copy: {
    width: '100%',
    marginTop: ['2rem', '', '0.5rem'],
    logo: {
      fontSize: ['1.625rem', '', '1.875rem'],
      fill: 'currentColor',
    },
    content: {
      width: '100%',
      alignItems: ['', 'center', ''],
      flexDirection: ['column', 'row', ''],
      flexWrap: ['', 'wrap', ''],
      marginTop: ['0.5rem', '', '1rem'],
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      alignItems: ['', 'center', ''],
      flexDirection: ['column', 'row', ''],
      '&:not(:last-child)': {
        marginRight: ['', '1.875rem', ''],
        marginBottom: ['0.5rem', 0, ''],
      },
    },
    listItem: {
      alignItems: 'center',
      '&:not(:last-of-type)': {
        marginRight: ['', '1.875rem', ''],
        marginBottom: ['0.5rem', 0, ''],
      },
    },
    text: {
      variant: 'text.smallBody',
    },
    link: {
      variant: 'footer.copy.text',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
};
