export default {
  wrapper: {
    marginBottom: [0, '10rem', ''],
    maxWidth: ['', '', '76rem'],
  },
  wrapperWide: {
    width: '100%',
    h2: {
      maxWidth: ['', '', '76rem'],
    },
  },
};
