import React from 'react';
import { Global } from '@emotion/core';
import '../assets/fonts/fonts.css';

const GlobalStyles: React.FC = () => (
  <Global
    styles={(theme) => ({
      '*': {
        WebkitFontSmoothing: 'antialiased',
      },
      body: {
        margin: 0,
        fontSize: '1rem',
        lineHeight: 1.4,
        fontFamily: theme.fonts.body,
        width: '100%',
        '&.is-fixed': {
          position: 'fixed',
        },
        '.tl-edges': {
          overflow: 'initial',
          width: '100%',
        },
        '.tl-wrapper': {
          float: 'none',
        },
      },
      button: {
        cursor: 'pointer',
      },
      a: {
        color: 'inherit',
        textDecoration: 'none',
      },
      svg: {
        '&:not([class])': {
          fill: 'currentColor',
        },
      },
      '::selection': {
        background: theme.colors.accent,
      },
      '::-moz-selection': {
        background: theme.colors.accent,
      },
    })}
  />
);

export default GlobalStyles;
