import text from '../../../gatsby-plugin-theme-ui/text';

const wrapperCommonStyles = {
  width: '100%',
  'p, ul:not([class]), ol:not([class])': {
    maxWidth: '64rem',
    margin: 0,
    '&:not(:last-child)': {
      marginBottom: ['1.6em', '', '1.3em'],
    },
    ...text.paragraph,
    '&.alt': {
      ...text.h4roman,
    },
  },
  'strong, b': {
    fontWeight: 'bold',
  },
  'h1, .title': {
    ...text.h0,
  },
  h1: {
    maxWidth: '64rem',
    margin: ['0 0 0.83em', '0 0 0.4em', '0 0 0.625em'],
  },
  h2: {
    maxWidth: '64rem',
    margin: '0 0 0.5rem',
    ...text.h1,
  },
  h3: {
    maxWidth: '64rem',
    margin: '0 0 0.5rem',
    ...text.h2,
  },
  h4: {
    maxWidth: '64rem',
    margin: '0 0 0.5rem',
    ...text.h3,
  },
  h5: {
    maxWidth: '64rem',
    margin: '0 0 0.5rem',
    ...text.h4roman,
  },
  'h5 strong': {
    fontWeight: 'black',
  },
  h6: {
    maxWidth: '64rem',
    margin: '0 0 0.5rem',
    ...text.h5,
  },
  '.category': {
    marginTop: 0,
    marginBottom: ['0.5rem', '', '1rem'],
    '+ *': {
      marginTop: 0,
    },
    ...text.tags,
  },
  '.list': {
    listStyle: 'none',
    padding: 0,
    margin: ['2.5rem 0', '', '10rem 0'],
    li: {
      '&:not(:last-child)': {
        marginBottom: ['2.5rem', '5rem', ''],
      },
      '&:not(:first-child)': {
        marginTop: ['2.5rem', '5rem', ''],
      },
      '> *': {
        margin: '0.5rem 0',
      },
    },
  },
};

export default {
  wrapper: {
    ...wrapperCommonStyles,
    a: {
      font: 'inherit',
      color: 'inherit',
      variant: 'links.underlinedAccent',
    },
  },
  wrapperCustomLink: {
    ...wrapperCommonStyles,
  },
  section: {
    maxWidth: '64rem',
    '&:not(:last-child)': {
      marginBottom: ['5rem', '', '10rem'],
    },
    '&:not(:first-child)': {
      marginTop: ['5rem', '', '10rem'],
    },
    h2: {
      marginBottom: '0.5rem',
      ...text.paragraph,
    },
    p: {
      ...text.h1,
      '&:not(:last-child)': {
        marginBottom: ['2.5rem', '5rem', ''],
      },
    },
    '.content': {
      '&:not(:last-child)': {
        marginBottom: ['2.5rem', '5rem', ''],
      },
    },
  },
  wrapperSimple: {
    ...text.smallBody,
    time: {
      display: 'block',
      marginBottom: ['1.25rem', '', '2.5rem'],
      ...text.tags,
    },
    h1: {
      margin: ['0 0 2.5rem', '', '0 0 5rem'],
      ...text.h2,
    },
    h2: {
      margin: 0,
      ...text.h6,
    },
    p: {
      margin: '0 0 2rem',
      a: {
        font: 'inherit',
        display: 'inline',
      },
    },
    'h2 + p': {
      marginTop: 0,
    },
    'p + h2, ul + h2': {
      marginTop: ['2.5rem', '', '4.25rem'],
    },
    ul: {
      paddingLeft: ['1.4rem', '2rem', ''],
      marginBottom: ['2.5rem', '', '3.125rem'],
    },
    'ul, ol': {
      margin: 0,
      '&:not(:last-child)': {
        marginBottom: '2rem',
      },
      li: {
        width: '100%',
        '&:not(:last-child)': {
          marginBottom: '1rem',
        },
      },
    },
    a: {
      variant: 'links.underlinedSmall',
      font: 'inherit',
      display: 'inline-block',
      marginTop: ['2.5rem', '', '3.125rem'],
    },
    address: {
      fontStyle: 'normal',
      margin: '0 0 2rem',
    },
  },
  wrapperArticle: {
    ...text.smallBody,
    maxWidth: '48.25rem',
    p: {
      margin: 0,
    },
    'p:not(:last-of-type)': {
      marginBottom: '2rem',
    },
    'ul, ol': {
      margin: 0,
      '&:not(:last-child)': {
        marginBottom: '2rem',
      },
    },
    ul: {
      listStyle: 'none',
      padding: 0,
      li: {
        position: 'relative',
        paddingLeft: '2rem',
        '&::before': {
          content: '"\u2022"',
          position: 'absolute',
          top: 0,
          left: '0.625rem',
        },
        '&:not(:last-of-type)': {
          marginBottom: '1rem',
        },
      },
    },
    h2: {
      margin: 0,
      ...text.h2,
    },
    h3: {
      margin: 0,
      ...text.h3,
    },
    h4: {
      margin: 0,
      ...text.h4roman,
    },
    'h4 strong': {
      fontWeight: 'black',
    },
    h5: {
      margin: 0,
      ...text.h5,
    },
    h6: {
      margin: 0,
      ...text.h6,
    },
    'a:not([class]):hover': {
      textDecoration: 'underline',
    },
    '.hs-form': {
      margin: 0,
      width: '100%',
    },
    '.hs-form-field': {
      width: '100%',
      marginBottom: '2.5rem',
    },
    '.hs-richtext + div .hs-dependent-field': {
      marginBottom: '2.5rem',
    },
    '.hs-form-field label': {
      color: 'text',
      ...text.smallBody,
    },
    '.input': {
      display: 'block',
    },
    '.hs-input[type="text"], .hs-input[type="number"], .hs-input[type="email"], .hs-input[type="tel"]': {
      width: '100%',
      margin: 0,
      backgroundColor: 'input',
      height: '2.5rem',
      border: 0,
      padding: '0 1.25rem',
      color: 'borderLight',
      outline: 'none',
      borderRadius: 0,
      WebkitAppearance: 'none',
      ...text.smallBody,
    },
    '.hs-submit': {
      marginTop: '2.5rem',
    },
    '.actions': {
      display: 'inline-flex',
      variant: 'links.underlinedSmall',
    },
    '.hs-button': {
      border: 0,
      padding: 0,
      backgroundColor: 'transparent',
      outline: 'none',
      cursor: 'pointer',
      ...text.smallBody,
    },
    '.inputs-list': {
      marginTop: '0.3125rem',
      listStyle: 'none',
      padding: 0,
      li: {
        padding: 0,
        '&::before': {
          display: 'none',
        },
        '&:not(:last-of-type)': {
          marginBottom: '0.3125rem',
        },
      },
    },
    '.hs-error-msgs li .hs-error-msg, .hs-error-msgs li .hs-main-font-element': {
      color: 'error',
      ...text.error,
    },
    '.hs-form-radio-display, .hs-form-booleancheckbox-display': {
      cursor: 'pointer',
      display: 'flex',
      position: 'relative',
      input: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        opacity: 0,
        height: 0,
        width: 0,
      },
      '> span': {
        display: 'block',
        paddingLeft: '2.25rem',
        position: 'relative',
        margin: 0,
        '&::before': {
          content: '""',
          position: 'absolute',
          height: '1.25rem',
          width: '1.25rem',
          backgroundColor: 'transparent',
          border: '1px solid #979797',
          boxShadow: '1px 1px 2px 0 rgba(0,0,0,0.5)',
          transition: '250ms ease-in-out',
          left: 0,
          top: '0.3125rem',
        },
      },
      'input:checked + span::before': {
        backgroundColor: 'accent',
      },
    },
    '.hs-form-radio-display span::before': {
      borderRadius: '100%',
    },
    '.hs-form-booleancheckbox-display span::before': {
      borderRadius: '2px',
    },
    '.legal-consent-container .hs-form-booleancheckbox-display > span': {
      margin: 0,
    },
  },
};
