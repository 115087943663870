import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FooterDataQuery, FooterInfoItem } from './types';
import { getFooterInfoItems } from './utils';

type IUseFooterData = {
  infoItems: FooterInfoItem[];
};

export const useFooterData = (): IUseFooterData => {
  const { footerItems }: FooterDataQuery = useStaticQuery(graphql`
    query {
      footerItems: allSanityNavigationMenu {
        edges {
          node {
            id
            title
            items {
              title
              link
              nofollow
              openNewTab
              _key
            }
          }
        }
      }
    }
  `);

  const infoItems = useMemo(() => getFooterInfoItems(footerItems.edges), [
    footerItems.edges,
  ]);

  return {
    infoItems,
  };
};
