import { bodySmall } from '../../../gatsby-plugin-theme-ui/text';
import content from './content/theme';

export default {
  wrapper: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'borderLight',
    '.slick-track': {
      display: 'flex',
    },
    '.slick-slide': {
      height: 'inherit',
    },
    '.is-light .slick-dots li button::before, .is-light .slick-dots li button::after': {
      backgroundColor: 'white',
    },
  },
  navigation: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: ['1.25rem 0', '', '2rem 0 5rem'],
    content: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    action: {
      paddingLeft: '1rem',
      marginLeft: 'auto',
      flexShrink: 0,
      button: {
        variant: 'buttons.underlinedAccent',
        transition: '250ms ease-in-out',
        ...bodySmall,
      },
    },
    '&.is-light': {
      color: 'white',
    },
  },
  content,
};
