import React, { useState } from 'react';
import Reveal from 'react-awesome-reveal';
import { fadeInAnimation } from '../../../gatsby-plugin-theme-ui/animations';
import useIsRevealAnimationFinished from '../../../hooks/useIsRevealAnimationFinished';
import { RevealAnimationContext } from './context';
import { RevealAnimationWrapperProps } from './types';

const RevealAnimationWrapper: React.FC<RevealAnimationWrapperProps> = ({
  children,
  damping,
  ...props
}) => {
  const [
    revealAnimatedElement,
    setRevealAnimatedElement,
  ] = useState<Element | null>(null);
  const isRevealAnimationFinished = useIsRevealAnimationFinished(
    revealAnimatedElement,
  );

  return (
    <div
      ref={(ref) => setRevealAnimatedElement(ref?.children[0] || null)}
      style={{ width: '100%' }}
    >
      <RevealAnimationContext.Provider value={isRevealAnimationFinished}>
        <Reveal
          keyframes={fadeInAnimation}
          triggerOnce
          duration={1000}
          damping={damping || 0}
          {...props}
        >
          {children}
        </Reveal>
      </RevealAnimationContext.Provider>
    </div>
  );
};

export default RevealAnimationWrapper;
