// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-preview-case-study-tsx": () => import("./../../../src/pages/preview/case-study.tsx" /* webpackChunkName: "component---src-pages-preview-case-study-tsx" */),
  "component---src-pages-preview-page-tsx": () => import("./../../../src/pages/preview/page.tsx" /* webpackChunkName: "component---src-pages-preview-page-tsx" */),
  "component---src-pages-preview-works-tsx": () => import("./../../../src/pages/preview/works.tsx" /* webpackChunkName: "component---src-pages-preview-works-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-insight-tsx": () => import("./../../../src/templates/insight.tsx" /* webpackChunkName: "component---src-templates-insight-tsx" */),
  "component---src-templates-insights-tsx": () => import("./../../../src/templates/insights.tsx" /* webpackChunkName: "component---src-templates-insights-tsx" */),
  "component---src-templates-job-tsx": () => import("./../../../src/templates/job.tsx" /* webpackChunkName: "component---src-templates-job-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-works-tsx": () => import("./../../../src/templates/works.tsx" /* webpackChunkName: "component---src-templates-works-tsx" */)
}

