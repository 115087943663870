export default {
  wrapper: {
    position: ['fixed', '', 'static'],
    top: [0, '', 'auto'],
    bottom: [0, '', 'auto'],
    right: [0, '', 'auto'],
    maxWidth: ['', '70%', '100%'],
    width: ['100%', '', 'auto'],
    backgroundColor: ['text', '', 'transparent'],
    color: ['white', '', 'text'],
    zIndex: 3,
    padding: ['5.25rem 1.25rem 1rem', '5.25rem 5.25rem 1rem', 0],
    flexDirection: 'column',
    transform: ['translateX(100%)', '', 'none'],
    transition: ['250ms ease-in-out', '', 'none'],
    opacity: [0, '', 1],
    '&.is-open': {
      transform: ['translateX(0)', '', 'none'],
      opacity: 1,
    },
  },
  heading: {
    display: ['flex', '', 'none'],
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    logo: {
      display: ['', '', 'none'],
      visibility: ['', 'hidden', ''],
    },
  },
  list: {
    listStyle: 'none',
    alignItems: ['flex-start', '', 'center'],
    flexWrap: ['', '', 'wrap'],
    flexDirection: ['column', '', 'row'],
    padding: 0,
    flexGrow: [1, '', 0],
    overflow: ['auto', '', 'initial'],
  },
  listItem: {
    flexShrink: 0,
    width: ['100%', '', 'auto'],
    '&:not(:last-child)': {
      marginRight: ['', '', '1.5rem'],
      marginBottom: ['1.5rem', '', 0],
    },
  },
  itemContent: {
    width: ['100%', '', 'auto'],
    alignItems: ['flex-start', '', 'center'],
    justifyContent: ['', '', 'center'],
    flexDirection: ['column', '', 'row'],
    '&.is-open': {
      '.sub-menu-wrapper': {
        maxHeight: ['999px', '', 'unset'],
      },
      '.navigation-arrow': {
        transform: 'rotate(90deg)',
      },
    },
  },
  linkWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: ['100%', '', 'auto'],
    '> a': {
      variant: 'links.nav',
    },
  },
  arrow: {
    display: ['inline-flex', '', 'none'],
    outline: 'none',
    padding: '0.75rem',
    flexShrink: 0,
    svg: {
      transition: '250ms ease-in-out',
      fontSize: '0.875rem',
      fill: 'currentColor',
    },
  },
  subListWrapper: {
    width: '100%',
    display: ['flex', '', 'none'],
    maxHeight: [0, '', 'unset'],
    overflow: ['hidden', '', 'initial'],
    transition: ['250ms ease-in-out', '', 'none'],
  },
};
