import { useStaticQuery, graphql } from 'gatsby';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { client } from '../../../utils/client';
import { HeaderDataQuery } from './types';
import { HeaderNavigationItem } from './Navigation/types';
import { buildNavigationItems } from './utils';

const query = `*[_type == "page" && _id == $pageId][0]{
  "slug": slug->slug.current,
}`;
const queryMenu = `*[_type == "menu"][0]{
  "elements": elements[]{
    _key,
    title,
    "slug": {
      "slug": {
  			"current": slug->slug.current
			}
    },
    "submenu": submenu[]->{
      title,
      slug
    }
  },
}`;

const queryStaticMenu = graphql`
  query {
    menuItems: sanityMenu {
      elements {
        _key
        title
        submenu {
          title
          slug {
            current
          }
        }
        slug {
          slug {
            current
          }
        }
        linkType
        url
      }
    }
  }
`;

type IUseHeaderData = {
  navItems: HeaderNavigationItem[];
  previewPageSlug: string;
};

export const useHeaderData = (): IUseHeaderData => {
  const location = useLocation();
  const [previewNavItems, setPreviewNavItems] = useState<
    HeaderDataQuery['menuItems']
  >([]);
  const [previewPageSlug, setPreviewPageSlug] = useState('');
  const { menuItems }: HeaderDataQuery = useStaticQuery(queryStaticMenu);

  const pageId = useMemo(() => queryString.parse(location.search)?.pageId, [
    location,
  ]);

  const fetchPreviewPageData = useCallback(async () => {
    const data = await client.fetch(query, { pageId });
    const menuData: HeaderDataQuery['menuItems'] = await client.fetch(
      queryMenu,
    );

    setPreviewNavItems(menuData);
    setPreviewPageSlug(data.slug);
  }, [pageId]);

  useEffect(() => {
    if (pageId) fetchPreviewPageData();
  }, [pageId, fetchPreviewPageData]);

  const navigationItems = useMemo(
    () => buildNavigationItems(menuItems.elements),
    [menuItems],
  );
  const previewNavigationItems = useMemo(
    () => buildNavigationItems(previewNavItems.elements),
    [previewNavItems],
  );

  return {
    navItems: pageId ? previewNavigationItems : navigationItems,
    previewPageSlug,
  };
};
