import menu from './Navigation/theme';
import subMenu from './SubMenu/theme';

export default {
  wrapper: {
    width: '100%',
    padding: ['1rem 0', '', '1.5rem 0'],
    backgroundColor: 'white',
    position: 'relative',
    transition: '250ms ease-in-out',
    '.is-animating &, .tl-wrapper-status--exited &, .tl-wrapper-status--exiting &': {
      opacity: 0,
    },
  },
  content: {
    justifyContent: 'space-between',
    alignItems: 'center',
    heading: {
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      color: 'text',
      '&.is-open': {
        position: 'relative',
        color: ['white', 'text', ''],
        zIndex: 4,
        '.hamburger': {
          color: 'white',
        },
      },
    },
  },
  logo: {
    flexShrink: 0,
  },
  hamburger: {
    display: ['block', '', 'none'],
    paddingLeft: ['2rem', '', 0],
    flexShrink: 0,
  },
  navigation: {
    flexGrow: ['', '', 1],
    alignItems: ['', '', 'center'],
    justifyContent: ['', '', 'flex-end'],
    position: ['fixed', '', 'static'],
    top: [0, '', 'auto'],
    bottom: [0, '', 'auto'],
    right: [0, '', 'auto'],
    maxWidth: ['', '70%', '100%'],
    width: ['100%', '', 'auto'],
    backgroundColor: ['text', '', 'transparent'],
    color: ['white', '', 'text'],
    zIndex: 3,
    padding: [
      '5.25rem 1.25rem 1rem',
      '5.25rem 2.5rem 1rem 5.25rem',
      '0 0 0 2rem',
    ],
    flexDirection: ['column', '', 'row'],
    transform: ['translateX(100%)', '', 'none'],
    transition: ['250ms ease-in-out', '', 'none'],
    opacity: [0, '', 1],
    '&.is-open': {
      transform: ['translateX(0)', '', 'none'],
      opacity: 1,
    },
  },
  menu,
  subMenuWrapper: {
    padding: ['2.5rem 1.25rem', '', '5rem 0'],
    width: ['calc(100% + 2.5rem)', '', '100%'],
    margin: ['0 -1.25rem', '', 0],
    whiteSpace: 'nowrap',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    WebkitOverflowScrolling: 'touch',
  },
  subMenu,
};
