export default {
  wrapper: {
    ul: {
      width: '100%',
      gridTemplateColumns: ['1fr', 'repeat(2, 50%)', 'repeat(4, 25%)'],
      gridGap: ['5rem', '3.25rem 0.5rem', ''],
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'grid',
    },
    li: {
      width: '100%',
      '&.featured': {
        gridColumn: ['span 1', 'span 2', ''],
      },
    },
    '.infinite-scroll-component': {
      overflow: 'visible !important',
    },
  },
};
