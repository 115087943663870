/** @jsx jsx */
import { Link } from 'gatsby';
import { Flex, jsx } from 'theme-ui';
import { HeaderSubMenuProps } from './types';

const HeaderSubMenu: React.FC<HeaderSubMenuProps> = ({
  items,
  isScrollable,
}) => (
  <Flex
    as="ul"
    variant={
      isScrollable ? 'header.subMenu.listScrollable' : 'header.subMenu.list'
    }
  >
    {items.map(({ label, to, mobileHidden }) => (
      <Flex
        as="li"
        variant={
          mobileHidden
            ? 'header.subMenu.listItemMobileHidden'
            : 'header.subMenu.listItem'
        }
        key={label}
      >
        <Link
          {...{ to }}
          sx={{
            variant: isScrollable
              ? 'header.subMenu.linkSimple'
              : 'header.subMenu.link',
          }}
          activeClassName="is-active"
          partiallyActive
        >
          {label}
        </Link>
      </Flex>
    ))}
  </Flex>
);

export default HeaderSubMenu;
