import text from '../../../../gatsby-plugin-theme-ui/text';

export default {
  wrapper: {
    minHeight: ['26.25rem', '27rem', '60.25rem'],
    '&.is-light': {
      color: 'white',
    },
  },
  main: {
    position: 'relative',
    zIndex: 1,
    padding: ['2.5rem 0 5rem', '', '5rem 0 10rem'],
  },
  category: {
    marginBottom: ['1.25rem', '', '2.5rem'],
    ...text.tags,
  },
  media: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: ['0 50%', '50% 50%', ''],
    display: 'block',
  },
  inner: {
    width: '100%',
    maxWidth: ['', '26rem', '47.25rem'],
    'h3 + p': {
      marginTop: '2.5rem',
    },
    p: {
      display: ['none', '', 'block'],
    },
    'p + a, h3 + a': {
      marginTop: ['2.5rem', '', '5rem'],
      display: 'inline-block',
    },
  },
};
