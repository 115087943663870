/** @jsx jsx */
import { jsx, Box, Button, Container, Flex, Text } from 'theme-ui';
import { Link } from 'gatsby';
import { useCookies } from 'react-cookie';
import { useCallback } from 'react';
import routes from '../../../routes';
import useHasMounted from '../../../hooks/useHasMounted';

const CookieBanner: React.FC = () => {
  const [cookies, setCookie] = useCookies();
  const hasMounted = useHasMounted();

  const handleCookieConsent = useCallback(() => {
    const newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() + 1);

    setCookie('cookieConsent', true, {
      expires: newDate,
    });
  }, [setCookie]);

  return cookies && !cookies.cookieConsent && hasMounted ? (
    <Box variant="cookieBanner.wrapper">
      <Container>
        <Flex variant="cookieBanner.content">
          <Text as="p" variant="smallBody">
            Luminous uses cookies to improve your online experience. Find out
            more about our{' '}
            <Link
              sx={{ variant: 'cookieBanner.content.link' }}
              to={routes.COOKIE_POLICY}
            >
              Cookie Policy
            </Link>
          </Text>
          <Flex variant="cookieBanner.buttonWrapper">
            <Button variant="cookieBanner.button" onClick={handleCookieConsent}>
              Close
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Box>
  ) : null;
};

export default CookieBanner;
