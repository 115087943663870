import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Container, Flex } from 'theme-ui';
import { globalHistory, useLocation } from '@reach/router';
import { useResponsiveValue } from '@theme-ui/match-media';
import useToggle from '../../../hooks/useToggle';
import disableScrollOnBody from '../../../utils/disableScrollOnBody';
import enableScrollOnBody from '../../../utils/enableScrollOnBody';

import Logo from '../../atoms/Logo';

import HeaderNavigation from './Navigation';
import HeaderSubMenu from './SubMenu';
import useHasMounted from '../../../hooks/useHasMounted';
import {
  scrollToActiveElement,
  isPreviewPageSlugInNavItems,
  isPathnameMatchesToActiveUrls,
} from './utils';
import Hamburger from '../../atoms/Hamburger';

import { useHeaderData } from './hooks';

const Header: React.FC = () => {
  const { navItems, previewPageSlug } = useHeaderData();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const subMenuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { pathname } = location;

  const hasMounted = useHasMounted();
  const isTablet = useResponsiveValue([false, false, true], {
    defaultIndex: 0,
  });
  const [isTabletView, setIsTabletView] = useState(isTablet);

  useLayoutEffect(() => {
    setIsTabletView(isTablet);
  }, [isTablet]);

  const handleOpenMenu = useCallback(() => {
    toggleOn();
  }, [toggleOn]);

  const handleCloseMenu = useCallback(() => {
    toggleOff();
  }, [toggleOff]);

  useEffect(() => {
    const unlisten = globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        toggleOff();
      }
    });

    if (isOpen) {
      disableScrollOnBody();
    } else {
      enableScrollOnBody();
    }

    if (isTabletView && hasMounted) {
      toggleOff();
    }

    if (subMenuRef.current && hasMounted) {
      scrollToActiveElement(subMenuRef.current);
    }
    return () => {
      unlisten();
    };
  }, [hasMounted, isTablet, isTabletView, toggleOff, pathname, isOpen]);

  const activeSubmenu = useMemo(() => {
    const navSubmenus = navItems.find(
      ({ subMenu: headerSubMenu, activeUrls, to }) =>
        headerSubMenu &&
        headerSubMenu?.length > 0 &&
        (isPathnameMatchesToActiveUrls(pathname, activeUrls) ||
          pathname.startsWith(to) ||
          isPreviewPageSlugInNavItems(previewPageSlug, to, headerSubMenu)),
    );

    return navSubmenus?.subMenu;
  }, [navItems, pathname, previewPageSlug]);

  return (
    <>
      <Box as="header" variant="header.wrapper">
        <Container>
          <Flex variant="header.content">
            <Flex
              variant="header.content.heading"
              className={isOpen ? 'is-open' : ''}
            >
              <Box variant="header.logo">
                <Logo />
              </Box>
              <Box variant="header.hamburger">
                <Hamburger
                  onClick={isOpen ? handleCloseMenu : handleOpenMenu}
                  {...{ isOpen }}
                />
              </Box>
            </Flex>
            <Flex
              as="nav"
              variant="header.navigation"
              className={isOpen ? 'is-open' : ''}
            >
              <HeaderNavigation items={navItems} />
            </Flex>
          </Flex>
        </Container>
      </Box>
      {activeSubmenu && activeSubmenu.length > 0 && (
        <Container>
          <Box variant="header.subMenuWrapper" ref={subMenuRef}>
            <HeaderSubMenu items={activeSubmenu} isScrollable />
          </Box>
        </Container>
      )}
    </>
  );
};

export default Header;
