export default {
  wrapper: {
    width: '1.25rem',
    height: '1.125rem',
    position: 'relative',
    margin: 0,
    transform: 'rotate(0deg)',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    padding: 0,
    border: 0,
    outline: 'none',
    color: 'inherit',
    span: {
      display: 'block',
      position: 'absolute',
      height: '2px',
      width: '100%',
      backgroundColor: 'currentColor',
      borderRadius: '2px',
      opacity: 1,
      left: 0,
      transform: 'rotate(0deg)',
      transition: '250ms ease-in-out',
      '&:nth-child(1)': {
        top: 0,
        transformOrigin: 'left center',
      },
      '&:nth-child(2)': {
        top: '0.4375rem',
        transformOrigin: 'left center',
      },
      '&:nth-child(3)': {
        top: '0.875rem',
        transformOrigin: 'left center',
      },
    },
    '&.is-open span': {
      '&:nth-child(1)': {
        transform: 'rotate(45deg)',
      },
      '&:nth-child(2)': {
        width: '0%',
      },
      '&:nth-child(3)': {
        transform: 'rotate(-45deg)',
      },
    },
  },
};
