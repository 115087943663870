export default {
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    position: 'relative'
  },
  button: {
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  track: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '.input-range': {
      width: '100%',
      cursor: 'pointer',
      '&:hover .input-range__track': {
        height: '0.375rem',
      },
    },
    '.input-range__label, .input-range__slider-container': {
      display: 'none',
    },
    '.input-range__track': {
      height: '0.1875rem',
      borderRadius: '0.1875rem',
      transition: 'height 250ms ease-in-out',
    },
    '.input-range__track--background': {
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    },
    '.input-range__track--active': {
      width: '100%',
      backgroundColor: 'white',
    },
  },
  checkbox: {
    position: 'relative',
    color: 'white',
    padding: '1rem',
    cursor: 'pointer',
    '.input': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0,
      height: 0,
      width: 0,
      overflow: 'hidden',
    },
    icon: {
      fill: 'currentColor',
      fontSize: '0.875rem',
    },
  },
};
