export default {
  list: {
    listStyle: 'none',
    padding: 0,
    width: ['100%', 'calc(100% + 0.5rem)', ''],
    margin: ['', '-0.25rem', ''],
    flexWrap: ['', 'wrap', ''],
    flexDirection: ['column', 'row', ''],
  },
  listItem: {
    width: ['100%', '50%', ''],
    padding: ['', '0.25rem', ''],
    '&:not(:last-child)': {
      marginBottom: ['5rem', 0, ''],
    },
  },
};
