import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Box, Button, Flex } from 'theme-ui';
import { rgba } from 'polished';
import { css } from '@emotion/core';
import { WithTheme } from '../../../gatsby-plugin-theme-ui';
import media from '../../../gatsby-plugin-theme-ui/mediaQueres';
import { ModalWrapperProps } from './types';

export const StyledModalActionWrapper = styled(Flex)<
  WithTheme<ModalWrapperProps>
>`
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  padding: 0 1.25rem;
  @media ${media.phone} {
    padding: 0 2.5rem;
  }
  @media ${media.tablet} {
    height: 4rem;
    top: 5rem;
    width: 13.125rem;
    background-color: transparent;
    left: ${({ isHorizontal }) => (isHorizontal ? 'auto' : 0)};
    bottom: ${({ isHorizontal }) => (isHorizontal ? 0 : 'auto')};
    align-items: ${({ isHorizontal }) =>
      isHorizontal ? 'flex-start' : 'center'};
    justify-content: ${({ isHorizontal }) =>
      isHorizontal ? 'center' : 'flex-end'};
  }
`;

export const StyledModalCloseButton = styled(Button)<WithTheme>`
  background-color: transparent;
  border-radius: 0;
  outline: none;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.text};
`;

const AnimatedWrapperBox = motion.custom(Flex);

export const StyledModalWrapper = styled(AnimatedWrapperBox)<
  WithTheme<ModalWrapperProps>
>`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: ${rgba(0, 0, 0, 0.8)};
  justify-content: center;
  align-items: center;
  @media ${media.phone} {
    padding: ${({ isAlignedRight }) => (isAlignedRight ? '0' : '1.5rem')};
  }
  @media ${media.tablet} {
    padding: ${({ isAlignedRight }) => (isAlignedRight ? '0' : '2.5rem')};
  }
`;

const AnimatedBox = motion.custom(Flex);

export const StyledModalContentWrapper = styled(AnimatedBox)<
  WithTheme<ModalWrapperProps>
>`
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme, isDark }) =>
    isDark ? theme.colors.input : theme.colors.white};
  ${({ theme, isDark }) =>
    isDark &&
    css`
      border: 1px solid ${theme.colors.borderDark};
    `};
  height: ${({ isAlignedRight }) => (isAlignedRight ? '100%' : 'auto')};
  max-height: 100%;
  @media ${media.tablet} {
    max-width: ${({ isAlignedRight }) =>
      isAlignedRight ? '77.625rem' : '40rem'};
    margin-left: ${({ isAlignedRight }) => (isAlignedRight ? 'auto' : 0)};
  }
`;

export const StyledModalContent = styled(Box)<ModalWrapperProps>`
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  padding: ${({ isAlignedRight }) =>
    isAlignedRight ? '5rem 1.25rem 10rem' : '1rem'};
  -webkit-overflow-scrolling: touch;
  @media ${media.phone} {
    padding: ${({ isAlignedRight }) =>
      isAlignedRight ? '5rem 2.5rem 3.5rem' : '1.5rem'};
  }
  @media ${media.tablet} {
    padding: ${({ isAlignedRight }) =>
      isAlignedRight ? '5rem 13.125rem 5rem 8.125rem' : '2.5rem'};
  }
  position: relative;
  &::after {
    padding: ${({ isAlignedRight }) => (isAlignedRight ? '3.2rem' : '1.2rem')};
    content: '';
    position: absolute;
    left: 0;
    right: 0;
  }
`;
