import { FooterDataQuery, FooterInfoItem } from './types';

export const getFooterInfoItems = (
  items: FooterDataQuery['footerItems']['edges'],
): FooterInfoItem[] => {
  const list = items.map(({ node: menu }) => ({
    key: menu.id,
    title: menu.title,
    items: menu.items.map((elMenu) => {
      const props = elMenu.link.startsWith('http')
        ? {
            href: elMenu.link,
          }
        : {
            to: elMenu.link,
          };
      return {
        // eslint-disable-next-line no-underscore-dangle
        key: elMenu._key,
        label: elMenu.title,
        ...props,
      };
    }),
  }));
  return list;
};
