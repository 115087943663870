/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { Logo as LogoSVG } from '../../../assets/svg';

const Logo: React.FC = () => (
  <Link to="/" sx={{ variant: 'logo.link' }}>
    <LogoSVG />
  </Link>
);

export default Logo;
