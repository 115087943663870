export default {
  wrapper: {
    width: '100%',
  },
  content: {
    width: '100%',
    marginTop: ['0.5rem', '', '1rem'],
  },
  tags: {
    variant: 'text.tags',
    color: 'muted',
    marginBottom: '1rem',
  },
  title: {
    marginBottom: '2rem',
    variant: 'text.h2',
  },
  quote: {
    variant: 'text.smallBody',
    maxWidth: '40.125rem',
    display: 'block',
    '&::before': {
      content: '"\u201C"',
    },
    '&::after': {
      content: '"\u201D"',
    },
  },
};
