import { HeaderNavigationItem } from './Navigation/types';
import {
  HeaderDataQuery,
  HeaderMenuItem,
  IIsPreviewPageSlugInNavItems,
  Submenu,
} from './types';

const scrollToActiveElement = (wrapper: HTMLElement): void => {
  const activeElement = wrapper.getElementsByClassName('is-active')[0];

  if (activeElement !== null && activeElement instanceof HTMLElement) {
    const activeElementScrollPosition = activeElement.offsetLeft;

    wrapper.scrollTo(activeElementScrollPosition - 20, 0);
  }
};

const isPreviewPageSlugInNavItems: IIsPreviewPageSlugInNavItems = (
  pageSlug,
  navPath,
  submenuElements,
) =>
  !!pageSlug &&
  (pageSlug.startsWith(navPath) ||
    submenuElements.some((submenu) => pageSlug.startsWith(submenu.to)));

const isPathnameMatchesToActiveUrls = (
  pathname: string,
  activeUrls: string[] | undefined,
): boolean => (activeUrls || []).some((url) => pathname.startsWith(url));

const createSubmenu = (elements: Submenu[] = []): HeaderMenuItem[] => {
  const items = elements.map((element) => ({
    label: element.title,
    to: element.slug.current,
  }));
  return items;
};

const buildNavigationItems = (
  elements: HeaderDataQuery['menuItems']['elements'] = [],
): HeaderNavigationItem[] => {
  const items = elements.map(({ linkType, ...item }) => {
    const itemSlug =
      linkType === 'internal' || !linkType
        ? item?.slug?.slug?.current
        : item?.url;

    const subMenuUrls = (item.submenu || []).map(
      (element) => element?.slug?.current,
    );
    // We have a special scenario on case studies => they are individual works
    // but have different url -> that's how it's done on the current production
    const workActiveUrls = itemSlug === '/work' ? ['/case-study'] : [];
    const insightActiveUrls = itemSlug?.includes('/insight')
      ? ['/insights']
      : [];

    return {
      label: item.title,
      to: itemSlug ?? '',
      linkType,
      // eslint-disable-next-line no-underscore-dangle
      key: item._key,
      subMenu: createSubmenu(item.submenu),
      activeUrls: [
        itemSlug,
        ...subMenuUrls,
        ...workActiveUrls,
        ...insightActiveUrls,
      ],
    };
  });

  return items;
};

export {
  scrollToActiveElement,
  isPreviewPageSlugInNavItems,
  isPathnameMatchesToActiveUrls,
  buildNavigationItems,
};
