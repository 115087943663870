import text from '../../../gatsby-plugin-theme-ui/text';

export default {
  wrapper: {
    width: '100%',
    padding: '1.375rem 0',
    backgroundColor: 'primary',
    color: 'white',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  content: {
    alignItems: ['', 'center', ''],
    flexDirection: ['column', 'row', ''],
    width: '100%',
    link: {
      variant: 'links.underlinedSmall',
      display: 'inline-block',
    },
  },
  buttonWrapper: {
    alignItems: 'center',
    justifyContent: ['', 'flex-end', ''],
    marginLeft: ['', 'auto', ''],
    flexShrink: 0,
    paddingLeft: ['', '2rem', ''],
    marginTop: ['0.5rem', 0, ''],
  },
  button: {
    variant: 'links.underlinedSmall',
    ...text.smallBody,
  },
};
