export default {
  wrapper: {
    width: 'calc(100% + 1rem)',
    margin: '0 -0.5rem',
    '.slide': {
      outline: 'none',
    },
    '.slick-active': {
      zIndex: 1,
    },
    '.slick-dots': {
      position: 'static',
      bottom: 'auto',
      textAlign: 'left',
      display: 'flex !important',
      flexWrap: 'wrap',
    },
    '.slick-dots li': {
      margin: '0 0.5rem',
      width: ['2.5rem', '', '4.5rem'],
      height: '0.5rem',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '@keyframes progress': {
      '0%': { width: 0 },
      '100%': { width: '100%' },
    },
    '.slick-dots li button': {
      height: '100%',
      width: '100%',
      padding: 0,
      position: 'relative',
      '&::before, &::after': {
        backgroundColor: 'text',
        opacity: 1,
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '0',
        transform: 'translateY(-50%)',
        display: 'flex',
        borderRadius: '2px',
      },
      '&::before': {
        height: '2px',
        width: '100%',
      },
      '&::after': {
        height: '4px',
        width: 0,
      },
    },
    '.slick-dots .slick-active button::after': {
      width: '100%',
    },
    '&.slider-autoplay .slick-dots li button::after': {
      width: '100%',
    },
    '&.slider-autoplay .slick-dots .slick-active button::after': {
      animation: 'progress 6s linear',
    },
    '&.slider-autoplay .slick-dots .slick-active ~ li button::after': {
      width: 0,
    },
  },
  slide: {
    padding: '0 0.5rem',
  },
};
