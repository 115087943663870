export default {
  wrapper: {
    padding: ['1rem 0', '', '2rem 0'],
  },
  list: {
    lisStyle: 'none',
    padding: 0,
    margin: 0,
    flexWrap: 'wrap',
  },
  listItem: {
    alignItems: 'center',
    flexShrink: 0,
    '&:not(:last-child)::after': {
      content: '"/"',
      font: 'inherit',
      display: 'inline-flex',
      margin: '0 0.25rem',
      color: 'muted',
    },
  },
  text: {
    color: 'muted',
    variant: 'text.tags',
  },
  linkText: {
    transition: '250ms ease-in-out',
    '&:hover': {
      color: 'accent',
    },
    variant: 'breadcrumbs.text',
  },
};
