import text from '../../../../../../build/web/src/gatsby-plugin-theme-ui/text';

export default {
  outerContainer: {
    position: 'relative',
    width: '100%',
  },
  inner: {
    position: 'sticky',
    top: 0,
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
  },
  innerContainer: {
    position: 'absolute',
    height: '100%',
    willChange: 'transform',
  },
  title: {
    ...text.h3,
  },
};
