import text from '../../../../../../build/web/src/gatsby-plugin-theme-ui/text';

export default {
  wrapper: {
    gridTemplateColumns: ['1fr', '', 'minmax(360px, 1fr) 3fr'],
    gridGap: ['2.375rem', '2.75rem', 0],
    a: {
      display: 'inline-block',
    },
  },
  details: {
    width: '100%',
    paddingRight: ['', '', '2rem'],
    flexDirection: 'column',
  },
  title: {
    marginBottom: ['0.5rem', '', '1rem'],
    variant: 'text.h3',
  },
  address: {
    fontStyle: 'normal',
    variant: 'text.paragraph',
  },
  link: {
    marginTop: '1.5rem',
    '&.zoom': {
      display: ['none', 'none', 'inline-block'],
    },
    '+ .phone': {
      display: ['', '', 'none'],
    },
    ...text.paragraph,
    variant: 'links.underlined',
  },
  media: {
    width: '100%',
    position: 'relative',
    icon: {
      position: 'absolute',
      height: ['32.3%', '30.9%', '18.3%'],
      width: ['8.6%', '', '5%'],
      zIndex: 1,
    },
  },
};
