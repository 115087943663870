import routes from '../../../routes';
import { FooterCopyLink, FooterInfoItem } from './types';

export const footerItems: FooterInfoItem = {
  title: 'Contact us',
  key: '1',
  items: [
    {
      label: '020 7101 1677',
      href: 'tel: 020 7101 1677',
      key: '1.1',
    },
    {
      label: 'justin.boucher@luminous.co.uk',
      href: 'mailto:justin.boucher@luminous.co.uk',
      key: '1.2',
    },
  ],
};

export const footerCopyLinks: FooterCopyLink[] = [
  {
    label: 'Privacy policy',
    to: routes.PRIVACY_POLICY,
    key: '1',
  },
  {
    label: 'Cookie policy',
    to: routes.COOKIE_POLICY,
    key: '2',
  },
];
