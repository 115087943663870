import Logo from './logo.svg';
import IconChevronRight from './chevron-right.svg';
import IconEye from './eye.svg';
import IconThunderstrike from './thunderstrike.svg';
import IconPin from './pin.svg';
import IconSoundOn from './sound-on.svg';
import IconSoundOff from './sound-off.svg';
import IconPlay from './play.svg';
import IconPause from './pause.svg';

export {
  Logo,
  IconChevronRight,
  IconEye,
  IconThunderstrike,
  IconPin,
  IconSoundOn,
  IconSoundOff,
  IconPlay,
  IconPause,
};
