/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';
import { HeaderNavigationProps } from './types';
import HeaderNavigationItem from './item';

const HeaderNavigation: React.FC<HeaderNavigationProps> = ({ items }) => (
  <Flex as="ul" variant="header.menu.list">
    {items.map(({ key, ...item }) => (
      <Flex as="li" variant="header.menu.listItem" {...{ key }}>
        <HeaderNavigationItem {...{ item }} />
      </Flex>
    ))}
  </Flex>
);

export default HeaderNavigation;
