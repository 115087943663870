export default {
  wrapper: {
    width: '100%',
    flexDirection: 'column',
  },
  wrapperHorizontal: {
    width: '100%',
    flexDirection: ['column', 'row', ''],
    justifyContent: 'space-between',
  },
  box: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: ['5rem', '4.5rem', '8rem'],
    },
  },
  boxHorizontal: {
    flexBasis: ['100%', 'calc(50% - 1rem)', 'calc(50% - 2rem)'],
    '&:not(:last-child)': {
      marginRight: ['', '1rem', '2rem'],
      marginBottom: ['5rem', 0, ''],
    },
    '.title': {
      position: ['', 'sticky', ''],
      top: ['', '1rem', ''],
    },
  },
  list: {
    padding: 0,
    margin: 0,
    width: '100%',
    listStyle: 'none',
    gridArea: 'list',
  },
  listItem: {
    width: '100%',
  },
  media: {
    width: '100%',
    gridArea: 'media',
    '> div': {
      position: ['', 'sticky', ''],
      top: ['', '1rem', ''],
    },
  },
  mediaVideo: {
    width: '100%',
    gridArea: 'video',
    '> div': {
      position: ['', '', 'sticky'],
      top: ['', '', '1rem'],
    },
  },
  mediaContent: {
    width: '100%',
    '> div': {
      width: '100%',
    },
  },
  buttonHeading: {
    position: 'relative',
    backgroundColor: 'transparent',
    color: 'text',
    border: 0,
    borderRadius: 0,
    padding: 0,
    outline: 'none',
    textAlign: 'left',
    '&:hover span': {
      backgroundSize: ['', '', '0 3px, 100% 3px'],
    },
    '&.is-active span': {
      backgroundSize: '0 3px, 100% 3px',
    },
    text: {
      variant: 'text.h1',
      zIndex: 1,
      position: 'relative',
      backgroundImage:
        'linear-gradient(#EFEFEF, #EFEFEF), linear-gradient(#F5FF00, #F5FF00)',
      backgroundSize: '100% 3px, 0 3px',
      backgroundPosition: '100% 100%, 0 100%',
      backgroundRepeat: 'no-repeat',
      transition: 'background-size 500ms ease-in-out',
    },
  },
  item: {
    maxWidth: '40rem',
  },
  itemWide: {
    width: '100%',
  },
  content: {
    marginTop: '0.5rem',
    list: {
      padding: 0,
      margin: 0,
      width: '100%',
      listStyle: 'none',
    },
    listItem: {
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: '1.5rem',
      },
    },
  },
};
