import {
  heroBannerScaleAnimation,
  pageMarginTopAnimation,
} from '../../../gatsby-plugin-theme-ui/animations';

export default {
  wrapperSmall: {
    paddingTop: ['2.25rem', '2.25rem', '4.75rem', ''],
  },
  wrapper: {
    paddingTop: ['5rem', '', '10rem'],
    paddingBottom: ['5rem', '', '10rem'],
  },
  listingWrapper: {
    paddingBottom: ['10rem', '', '20rem'],
  },
  link: {
    variant: 'links.underlined',
    display: 'inline-flex',
  },
  hero: {
    wrapper: {
      width: '100%',
      padding: ['5rem 0 7rem', '5rem 0', '10rem 0'],
      maxWidth: '80rem',
    },
  },
  offer: {
    width: '100%',
    maxWidth: '76rem',
  },
  work: {
    list: {
      width: '100%',
      listStyle: 'none',
      padding: 0,
    },
    listItem: {
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: '0.5rem',
      },
    },
  },
  insights: {
    wrapper: {
      width: '100%',
    },
  },
  animated: {
    width: '100%',
  },
  pageWrapper: {
    transition: 'opacity .45s ease-in-out',
    backgroundColor: 'white',
    '&.is-exiting': {
      bottom: 0,
      left: 0,
      position: 'fixed',
      width: '100%',
    },
    '&.is-entering': {
      marginTop: 0,
      animation: `${pageMarginTopAnimation} 0.5s ease-in-out 0s`,
    },
    '&.is-entering-start': {
      marginTop: 'calc(90vh - var(--header-height))',
    },
    content: {
      // overflow: 'hidden',
    },
  },
  heroBannerWrapper: {
    position: 'relative',
    transformOrigin: 'top left',
    '> *': {
      transition: 'opacity .5s ease-in-out .2s',
    },
    '&.is-hidden > *': {
      opacity: 0,
      transition: 'none',
    },
    '.is-entering &': {
      transform: 'scaleY(0.2)',
    },
    '.is-entering-start &': {
      transform: 'scaleY(0.2)',
    },
    '.is-scrolled &': {
      animation: `${heroBannerScaleAnimation} 0.5s ease-in-out 0s`,
      transform: 'scaleY(1)',
    },
    content: {
      width: '100%',
      '> *': {
        transition: 'opacity .3s ease-in-out .2s',
        '.is-entering &': {
          opacity: 0.001,
          transition: 'none',
        },
      },
    },
  },
};
