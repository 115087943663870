import controls from './Controls/theme';
import poster from './Poster/theme';

const playCommonStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  backgroundColor: 'rgba(255,255,255,0.8)',
  color: 'text',
  zIndex: 1,
  transition: '400ms ease-in-out',
};

export default {
  wrapper: {
    padding: 0,
    width: '100%',
    height: '100%',
    '&:hover .is-visible.controls': {
      opacity: ['', '', 1],
    },
    video: {
      outline: 'none',
    },
    '.react-player__preview': {
      width: '100%',
      transition: '400ms ease-in-out',
    },
    '&:hover': {
      '.react-player__preview': {
        transform: 'scale(1.05)',
      },
      '.play': {
        transform: 'scale(0.95)',
      },
    },
  },
  play: {
    height: ['2.5rem', '3.75rem', '10rem'],
    width: ['2.5rem', '3.75rem', '10rem'],
    ...playCommonStyles,
    icon: {
      fontSize: ['1.5rem', '2rem', '5.2rem'],
    },
  },
  playSmall: {
    height: ['2.5rem', '3.75rem', '4.125rem'],
    width: ['2.5rem', '3.75rem', '4.125rem'],
    ...playCommonStyles,
    icon: {
      fontSize: ['1.5rem', '2rem', '2.25rem'],
    },
  },
  player: {
    height: '100%',
    width: '100%',
    '&.is-visible': {
      zIndex: 2,
      position: 'relative',
    },
  },
  controlsWrapper: {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    padding: '1rem 0',
    transform: 'translateX(-50%)',
    width: '100%',
    justifyContent: 'center',
    opacity: ['', '', 0],
    transition: '250ms ease-in-out',
    backgroundImage:
      'linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%)',
    '&.is-paused': {
      opacity: 1,
    },
    '&.is-visible': {
      opacity: [1, '', 0],
      // position: 'relative',
      zIndex: 3,
    },
  },
  controls,
  poster,
};
