/** @jsx jsx */
import { Button, Flex, jsx, Link as ThemedLink } from 'theme-ui';
import { LinkGetProps } from '@reach/router';
import { Link } from 'gatsby';
import { useCallback } from 'react';
import { HeaderNavigationItemProps } from './types';
import HeaderSubMenu from '../SubMenu';
import { IconChevronRight } from '../../../../assets/svg';
import useToggle from '../../../../hooks/useToggle';

const HeaderNavigationItem: React.FC<HeaderNavigationItemProps> = ({
  item,
}) => {
  const { label, to, subMenu, activeUrls, linkType } = item;
  const [isOpen, { toggle }] = useToggle();

  const isActive = useCallback(
    ({ location, isCurrent, isPartiallyCurrent }: LinkGetProps) => {
      if (
        activeUrls?.find((url: string) => location.pathname.startsWith(url)) ||
        isCurrent ||
        isPartiallyCurrent
      ) {
        return { className: 'is-active' };
      }

      return {};
    },
    [activeUrls],
  );

  return (
    <Flex variant="header.menu.itemContent" className={isOpen ? 'is-open' : ''}>
      <Flex variant="header.menu.linkWrapper">
        {linkType === 'external' ? (
          <ThemedLink href={to}>{label}</ThemedLink>
        ) : (
          <Link {...{ to }} getProps={isActive}>
            {label}
          </Link>
        )}

        {subMenu && subMenu.length > 0 && (
          <Button variant="header.menu.arrow" onClick={toggle}>
            <IconChevronRight className="navigation-arrow" />
          </Button>
        )}
      </Flex>
      {subMenu && subMenu.length > 0 && (
        <Flex variant="header.menu.subListWrapper" className="sub-menu-wrapper">
          <HeaderSubMenu items={subMenu} />
        </Flex>
      )}
    </Flex>
  );
};

export default HeaderNavigationItem;
