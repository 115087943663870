export default {
  wrapper: {
    width: '100%',
    marginBottom: ['10rem', '', '20rem'],
  },
  wrapperSmall: {
    width: '100%',
    marginBottom: ['5rem', '', '10rem'],
  },
};
