export default {
  width: '100%',
  '&:not(:last-of-type)': {
    marginBottom: '10rem',
  },
  title: {
    maxWidth: '62rem',
    marginBottom: ['5rem', '', '10rem'],
    variant: 'text.h2',
  },
};
