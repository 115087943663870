export default {
  wrapper: {
    width: '100%',
    '&:hover': {
      '.gatsby-image-wrapper': {
        transform: 'scale(1.05)',
      },
      '.action::after': {
        width: '100%',
      },
      '.icon': {
        opacity: 1,
        visibility: 'visible',
      },
    },
    media: {
      width: '100%',
      position: 'relative',
      marginBottom: ['1rem', '', '2rem'],
      icon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: ['4rem', '', '6rem'],
        zIndex: 2,
        opacity: 0,
        visibility: 'hidden',
        transition: '400ms ease-in-out',
      },
      iconSmall: {
        variant: 'relatedPageTile.wrapper.media.icon',
        fontSize: ['2rem', '3rem', '4rem'],
      },
    },
    content: {
      maxWidth: ['', '', '82%'],
      paddingRight: ['', '1rem', ''],
    },
  },
};
