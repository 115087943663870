import React from 'react';
import { Box, Button } from 'theme-ui';
import { HamburgerProps } from './types';

const Hamburger: React.FC<HamburgerProps> = ({ onClick, isOpen }) => (
  <Button
    variant="hamburger.wrapper"
    {...{ onClick }}
    className={`hamburger ${isOpen ? 'is-open' : ''}`}
  >
    <Box as="span" />
    <Box as="span" />
    <Box as="span" />
  </Button>
);

export default Hamburger;
