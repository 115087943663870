export default {
  wrapper: {
    width: '100%',
    padding: ['2.5rem 0', '', '5rem 0'],
  },
  content: {
    whiteSpace: 'nowrap',
    overflow: 'auto',
    width: ['calc(100% + 2.5rem)', 'calc(100% + 5em)', '100%'],
    margin: ['0 -1.25rem', '0 -2.5rem', 0],
    scrollBehavior: 'smooth',
    WebkitOverflowScrolling: 'touch',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: ['0 1.25rem', '0 2.5rem', 0],
  },
  listItem: {
    flexShrink: 0,
    paddingRight: ['1rem', '', '3rem'],
  },
  link: {
    variant: 'links.filter',
  },
};
