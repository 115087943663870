import React from 'react';
import { CookiesProvider } from 'react-cookie';
import GlobalStyles from '../../gatsby-plugin-theme-ui/GlobalStyles';
import { ModalProvider } from '../molecules/Modal';
import CookieBanner from '../organisms/CookieBanner';
import Footer from '../organisms/Footer';
import { footerCopyLinks, footerItems } from '../organisms/Footer/consts';
import Header from '../organisms/Header';

const Layout: React.FC = ({ children }) => (
  <>
    <CookiesProvider>
      <GlobalStyles />
      <ModalProvider>
        <Header />
        {children}
        <Footer item={footerItems} links={footerCopyLinks} />
        <CookieBanner />
      </ModalProvider>
    </CookiesProvider>
  </>
);

export default Layout;
