import text, { bodySmall } from './text';
import hamburger from '../components/atoms/Hamburger/theme';
import logo from '../components/atoms/Logo/theme';
import image from '../components/atoms/Image/theme';
import articleTile from '../components/molecules/ArticleTile/theme';
import breadcrumbs from '../components/molecules/Breadcrumbs/theme';
import carousel from '../components/molecules/Carousel/theme';
import pageFilters from '../components/molecules/PageFilters/theme';
import relatedPageTile from '../components/molecules/RelatedPageTile/theme';
import peopleTile from '../components/molecules/PeopleTile/theme';
import tags from '../components/molecules/Tags/theme';
import relatedWork from '../components/organisms/RelatedWork/theme';
import thumbnailTile from '../components/molecules/ThumbnailTile/theme';
import video from '../components/molecules/Video/theme';
import cmsContent from '../components/molecules/CmsContent/theme';
import hero from '../components/molecules/Hero/theme';
import heroSmall from '../components/molecules/HeroSmall/theme';
import horizontalCarousel from '../components/molecules/HorizontalCarousel/theme';
import imagesGrid from '../components/molecules/ImagesGrid/theme';
import infoGrid from '../components/molecules/InfoGrid/theme';
import mapBox from '../components/molecules/MapBox/theme';
import modal from '../components/molecules/Modal/theme';
import articlesCarousel from '../components/organisms/ArticlesCarousel/theme';
import articlesListing from '../components/organisms/ArticlesListing/theme';
import articlesSection from '../components/organisms/ArticlesSection/theme';
import cookieBanner from '../components/organisms/CookieBanner/theme';
import dismissableSection from '../components/organisms/DismissableSection/theme';
import footer from '../components/organisms/Footer/theme';
import header from '../components/organisms/Header/theme';
import infoBanner from '../components/organisms/InfoBanner/theme';
import infoBoxList from '../components/organisms/InfoBoxList/theme';
import mapBoxList from '../components/organisms/MapBoxList/theme';
import peopleCarousel from '../components/organisms/PeopleCarousel/theme';
import relatedPages from '../components/organisms/RelatedPages/theme';
import twitterCarousel from '../components/organisms/TwitterCarousel/theme';
import workListing from '../components/organisms/WorkListing/theme';
import pages from '../components/pages/theme';
import articleLayout from '../components/Layout/article/theme';

export const underlinedLinkBase = {
  display: 'inline',
  borderWidth: '2px 0',
  borderStyle: 'solid',
  borderTopColor: 'transparent',
  position: 'relative',
  backgroundColor: 'transparent',
  outline: 'none',
  borderRadius: 0,
  color: 'inherit',
  '&:hover::after': {
    width: ['', '', '100%'],
  },
};

export const underlinedLinkBaseAfter = {
  content: '""',
  position: 'absolute',
  height: '2px',
  bottom: '-2px',
  left: 0,
  width: 0,
  transition: '250ms ease-in-out',
  display: 'flex',
};

const theme = {
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#000',
    white: '#fff',
    accent: '#F5FF00',
    border: '#EFEFEF',
    muted: '#9B9B9B',
    borderLight: '#616161',
    borderDark: '#979797',
    input: '#f6f6f6',
    error: '#CD0202',
  },
  breakpoints: ['48rem', '64rem', '80rem'],
  fonts: {
    body: '"ARS Maquette Pro", sans-serif',
    heading: '"Publico", serif',
  },
  fontWeights: {
    light: 200,
    regular: 400,
    bold: 700,
    black: 800,
  },
  text,
  sizes: {
    container: '107rem',
  },
  layout: {
    container: {
      paddingLeft: ['1.25rem', '2.5rem', '5rem'],
      paddingRight: ['1.25rem', '2.5rem', '5rem'],
    },
    containerNarrow: {
      maxWidth: ['', '', '58.25rem'],
      paddingLeft: ['1.25rem', '2.5rem', '5rem'],
      paddingRight: ['1.25rem', '2.5rem', '5rem'],
    },
  },
  articleLayout,
  links: {
    simple: {
      padding: [0, '', '0.25rem 0'],
      borderBottomColor: 'accent',
      ...underlinedLinkBase,
    },
    nav: {
      variant: 'text.menu',
      padding: '0.375rem 0',
      borderBottomColor: ['borderLight', '', 'border'],
      '&::after': {
        ...underlinedLinkBaseAfter,
        backgroundColor: 'accent',
      },
      ...underlinedLinkBase,
      '&.is-active::after': {
        width: '100%',
      },
    },
    subNav: {
      variant: 'text.menu',
      padding: '0.375rem 0',
      borderBottomColor: 'border',
      '&::after': {
        ...underlinedLinkBaseAfter,
        backgroundColor: 'accent',
      },
      ...underlinedLinkBase,
      '&.is-active::after': {
        width: '100%',
      },
    },
    underlined: {
      borderBottomColor: 'border',
      padding: [0, '', '0.375rem 0'],
      '&::after': {
        ...underlinedLinkBaseAfter,
        backgroundColor: 'accent',
      },
      ...bodySmall,
      ...underlinedLinkBase,
    },
    underlinedSmall: {
      borderBottomColor: 'border',
      padding: [0, '', '0.25rem 0'],
      '&::after': {
        ...underlinedLinkBaseAfter,
        backgroundColor: 'accent',
      },
      ...underlinedLinkBase,
    },
    underlinedLight: {
      variant: 'links.underlined',
      borderBottomColor: 'white',
      '&::after': {
        ...underlinedLinkBaseAfter,
        backgroundColor: 'text',
      },
    },
    underlinedAccent: {
      variant: 'links.underlined',
      borderBottomColor: 'accent',
      '&::after': {
        ...underlinedLinkBaseAfter,
        backgroundColor: 'text',
      },
    },
    underlinedInline: {
      backgroundColor: 'transparent',
      outline: 'none',
      borderRadius: 0,
      color: 'text',
      '&:hover span:not([class]), &:hover div:not([class])': {
        backgroundSize: ['', '', '0 3px, 100% 3px'],
      },
      '&.is-active span:not([class]), &.is-active div:not([class])': {
        backgroundSize: '0 3px, 100% 3px',
      },
      'span:not([class]), div:not([class])': {
        padding: [0, '', '0.375rem 0'],
        zIndex: 1,
        position: 'relative',
        display: 'inline',
        backgroundImage:
          'linear-gradient(#EFEFEF, #EFEFEF), linear-gradient(#F5FF00, #F5FF00)',
        backgroundSize: '100% 3px, 0 3px',
        backgroundPosition: '100% 100%, 0 100%',
        backgroundRepeat: 'no-repeat',
        transition: 'background-size 500ms ease-in-out',
        ...bodySmall,
      },
    },
    filter: {
      variant: 'text.filter',
      transition: '250ms ease-in-out',
      color: 'text',
      '&:hover, &.is-active': {
        textDecoration: 'underline',
      },
    },
  },
  buttons: {
    video: {
      backgroundColor: 'transparent',
      color: 'white',
      padding: '1rem',
      border: 0,
      outline: 'none',
      transition: '250ms ease-in-out',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        fontSize: '0.875rem',
        fill: 'currentColor',
      },
    },
    underlined: {
      borderBottomColor: 'white',
      padding: [0, '', '0.25rem 0'],
      '&::after': {
        ...underlinedLinkBaseAfter,
        backgroundColor: 'accent',
      },
      ...underlinedLinkBase,
    },
    underlinedAccent: {
      borderBottomColor: 'accent',
      padding: [0, '', '0.25rem 0'],
      '&::after': {
        ...underlinedLinkBaseAfter,
        backgroundColor: 'white',
      },
      ...underlinedLinkBase,
    },
  },
  hamburger,
  image,
  logo,
  articleTile,
  breadcrumbs,
  carousel,
  pageFilters,
  relatedWork,
  relatedPageTile,
  peopleTile,
  tags,
  thumbnailTile,
  video,
  cmsContent,
  hero,
  heroSmall,
  horizontalCarousel,
  imagesGrid,
  infoGrid,
  mapBox,
  modal,
  articlesCarousel,
  articlesListing,
  articlesSection,
  cookieBanner,
  dismissableSection,
  footer,
  header,
  infoBanner,
  infoBoxList,
  mapBoxList,
  peopleCarousel,
  relatedPages,
  twitterCarousel,
  workListing,
  pages,
  styles: {
    spinnerWrapper: {
      margin: '2rem',
      alignItems: 'center',
      justifyContent: 'center',
      with: '100%',
    },
    spinner: {
      color: 'accent',
    },
  },
};

export default theme;

export type Theme = typeof theme;

export type WithTheme<T = unknown> = { theme: Theme } & T;
