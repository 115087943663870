export default {
  wrapper: {
    width: '100%',
    '&:hover .media': {
      '.media-content': {
        transform: 'scale(1.05)',
      },
      '&::after, .icon': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
  media: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0,
      visibility: 'hidden',
      transition: '400ms ease-in-out',
    },
    '> div': {
      width: '100%',
    },
    icon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: ['4rem', '', '6rem'],
      zIndex: 2,
      opacity: 0,
      visibility: 'hidden',
      transition: '400ms ease-in-out',
    },
  },
  mediaContent: {
    width: '100%',
    transition: '400ms ease-in-out',
  },
  details: {
    width: '100%',
    paddingRight: '1.5rem',
  },
  tag: {
    variant: 'text.tags',
    color: 'muted',
    display: 'block',
  },
};
