/** @jsx jsx */
import { jsx, Box, Flex, Text } from 'theme-ui';
import { Link } from 'gatsby';
import { useMemo } from 'react';
import { Logo } from '../../../assets/svg';
import { FooterCopyProps } from './types';

const FooterCopy: React.FC<FooterCopyProps> = ({ links }) => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <Box variant="footer.copy">
      <Logo sx={{ variant: 'footer.copy.logo' }} />
      <Flex variant="footer.copy.content">
        <Flex as="ul" variant="footer.copy.list">
          {links.map(({ key, label, to }) => (
            <Flex as="li" variant="footer.copy.listItem" {...{ key }}>
              <Link {...{ to }} sx={{ variant: 'footer.copy.link' }}>
                {label}
              </Link>
            </Flex>
          ))}
        </Flex>
        <Text as="p" variant="footer.copy.text">
          &copy; {currentYear} Luminous. All rights reserved.
        </Text>
      </Flex>
    </Box>
  );
};

export default FooterCopy;
