export default {
  list: {
    listStyle: 'none',
    padding: ['1.25rem', '', 0],
    flexWrap: ['', '', 'wrap'],
    flexDirection: ['column', '', 'row'],
    width: ['100%', '', 'calc(100% + 1.5rem)'],
    margin: ['', '', '0 -0.75rem'],
  },
  listScrollable: {
    listStyle: 'none',
    width: '100%',
    padding: 0,
    whiteSpace: 'nowrap',
    '> li': {
      padding: '0 1.5rem 0 0',
      flexShrink: 0,
      '&:not(:last-child)': {
        marginBottom: 0,
      },
    },
  },
  listItem: {
    padding: ['', '', '0 0.75rem'],
    '&:not(:last-child)': {
      marginBottom: ['1.25rem', '', 0],
    },
  },
  listItemMobileHidden: {
    padding: ['', '', '0 0.75rem'],
    display: ['none !important', '', 'flex'],
  },
  link: {
    variant: 'links.nav',
  },
  linkSimple: {
    variant: 'links.subNav',
  },
};
