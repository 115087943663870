import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';

import { AnimatePresence } from 'framer-motion';
import { ModalBaseProps } from './types';
import { useBlockBodyWhenModalIsOpened, useModalContextValue } from './hooks';
import {
  StyledModalActionWrapper,
  StyledModalCloseButton,
  StyledModalContent,
  StyledModalContentWrapper,
  StyledModalWrapper,
} from './styles';

const ModalContext = createContext<HTMLDivElement | undefined>(undefined);

const ModalProvider: React.FC = ({ children }) => {
  const { modalContextValue, modalContainerRef } = useModalContextValue();

  useBlockBodyWhenModalIsOpened(modalContainerRef.current);

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <div ref={modalContainerRef} />
      </AnimatePresence>
      <ModalContext.Provider value={modalContextValue}>
        {children}
      </ModalContext.Provider>
    </>
  );
};

const ModalBase: React.FC<ModalBaseProps> = ({
  onClose,
  withCloseIcon,
  isHorizontal,
  isAlignedRight,
  isDark,
  children,
}) => {
  const modalNode = useContext(ModalContext);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(
    (e) => {
      if (modalRef && modalRef.current) {
        if (!modalRef.current.contains(e.target)) {
          onClose();
        }
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return modalNode
    ? ReactDOM.createPortal(
        <FocusTrap>
          <StyledModalWrapper
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: 'easeOut',
              duration: 0.25,
            }}
            {...{ isAlignedRight }}
          >
            <StyledModalContentWrapper
              ref={modalRef}
              initial={
                isAlignedRight
                  ? { x: '100%' }
                  : { transform: 'scale(0)', opacity: 0 }
              }
              animate={
                isAlignedRight
                  ? { x: '0%' }
                  : { transform: 'scale(1)', opacity: 1 }
              }
              exit={
                isAlignedRight
                  ? { x: '100%' }
                  : { transform: 'scale(0)', opacity: 0 }
              }
              transition={{
                ease: 'easeOut',
                delay: 0.25,
                duration: 0.25,
              }}
              {...{ isHorizontal, isAlignedRight, isDark }}
            >
              {withCloseIcon && onClose && (
                <StyledModalActionWrapper
                  {...{ isHorizontal }}
                  variant="text.smallBody"
                >
                  <StyledModalCloseButton
                    onClick={onClose}
                    variant="links.underlinedSmall"
                  >
                    Close
                  </StyledModalCloseButton>
                </StyledModalActionWrapper>
              )}
              <StyledModalContent {...{ isHorizontal, isAlignedRight }}>
                {children}
              </StyledModalContent>
            </StyledModalContentWrapper>
          </StyledModalWrapper>
        </FocusTrap>,
        modalNode,
      )
    : null;
};

export { ModalProvider, ModalBase as default };
