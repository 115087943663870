export default {
  wrapper: {
    listStyle: 'none',
    width: '100%',
    padding: 0,
    margin: 0,
  },
  item: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: '5rem',
    },
  },
};
