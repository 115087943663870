/** @jsx jsx */
import { jsx, Box, Grid, Text, Link as ThemedLink } from 'theme-ui';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FooterInfoProps } from './types';

const FooterInfo: React.FC<FooterInfoProps> = ({ items }) => (
  <Grid variant="footer.info">
    {items.map(({ key, title, items: links }) => (
      <Box {...{ key }} variant="footer.info.box">
        <Text
          as="h4"
          variant="footer.info.box.title"
          mb={['0.5rem', '0.5rem', '1rem']}
        >
          {title}
        </Text>
        <Box as="ul" variant="footer.info.box.list">
          {links.map(({ key: linkKey, label, href, to }) => (
            <Box as="li" key={linkKey} variant="footer.info.box.listItem">
              {to ? (
                <AnchorLink
                  {...{ to }}
                  sx={{
                    variant: 'footer.info.box.link',
                  }}
                  stripHash
                >
                  <span>{label.replace(/@/gim, '@\u200b')}</span>
                </AnchorLink>
              ) : (
                <ThemedLink
                  {...{ href }}
                  target="_blank"
                  rel="noreferrer"
                  variant="footer.info.box.link"
                >
                  <span>{label.replace(/@/gim, '@\u200b')}</span>
                </ThemedLink>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    ))}
  </Grid>
);

export default FooterInfo;
