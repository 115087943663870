export default {
  list: {
    listStyle: 'none',
    padding: 0,
    width: 'calc(100% + 0.5rem)',
    margin: '-0.25rem',
    flexWrap: 'wrap',
  },
  listItem: {
    padding: '0.25rem',
    flexBasis: ['100%', '50%', ''],
  },
};
