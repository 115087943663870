export default {
  wrapper: {
    backgroundColor: 'accent',
    padding: ['5rem 0', '', '10rem 0'],
  },
  row: {
    width: '100%',
    maxWidth: '80.75rem',
    '&:not(:last-of-type)': {
      marginBottom: ['1.25rem', '', '2.5rem'],
    },
    a: {
      wordBreak: 'break-all',
    },
  },
};
