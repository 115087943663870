import text from '../../../../../../build/web/src/gatsby-plugin-theme-ui/text';

export default {
  wrapper: {
    width: '100%',
    marginBottom: ['10rem', '', '', 0],
    position: ['', '', 'relative'],
    '.slick-dots': {
      marginTop: ['3.125rem', '5.625rem', '3.125rem'],
    },
  },
  title: {
    marginBottom: ['5rem', '', '8rem'],
    ...text.h3,
  },
};
