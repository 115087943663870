export default {
  list: {
    listStyle: 'none',
    padding: 0,
    margin: '-0.25rem',
    flexWrap: 'wrap',
    width: 'calc(100% + 0.5rem)',
  },
  listItem: {
    padding: '0.25rem',
    flexBasis: '100%',
  },
  listItemSmall: {
    variant: 'workListing.listItem',
    flexBasis: ['100%', '50%', ''],
  },
};
