export default {
  cms: {
    width: '100%',
    maxWidth: '48.25rem',
    variant: 'text.smallBody',
    'p + p': {
      marginTop: '2rem',
    },
    a: {
      font: 'inherit',
      wordBreak: 'break-word',
      display: 'inline-block',
    },
    'p a': {
      display: 'inline',
      wordBreak: 'normal',
    },
  },
};
