import text from '../../../gatsby-plugin-theme-ui/text';

export default {
  wrapper: {
    width: '100%',
    padding: ['2.5rem 0 0', '', '5rem 0 0'],
    color: 'text',
  },
  grid: {
    gridTemplateColumns: ['1fr', '', '1fr 4fr'],
    gridGap: ['2.5rem', '', '2rem'],
    marginBottom: ['5rem', '', '10rem'],
  },
  heading: {
    gridColumn: ['1 / -1', '', '2 / 3'],
    width: '100%',
    maxWidth: '48.25rem',
    date: {
      display: 'block',
      marginBottom: ['1.25rem', '', '2.5rem'],
      ...text.tags,
    },
    title: {
      ...text.h2,
    },
    subtitle: {
      marginTop: ['2.5rem', '', '5rem'],
      ...text.paragraph,
    },
  },
  aside: {
    width: '100%',
    gridRow: ['2 / 3', '', '1 / -1'],
  },
  author: {
    width: '100%',
    position: ['', '', 'sticky'],
    top: ['', '', '1rem'],
    media: {
      maxWidth: '15.75rem',
    },
    name: {
      marginTop: '0.5rem',
      ...text.h4black,
    },
    email: {
      variant: 'links.underlinedInline',
      wordBreak: 'break-word',
      display: 'inline-block',
      'div:not([class])': {
        ...text.smallBody,
      },
    },
  },
  content: {
    width: '100%',
    media: {
      width: '100%',
      marginBottom: ['5rem', '', '10rem'],
    },
  },
};
