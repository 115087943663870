const routes = {
  HOME: '/',
  ABOUT: '/about',
  ABOUT_BRANDING: '/what-we-do/branding',
  ABOUT_REPORTING: '/what-we-do/reporting',
  ABOUT_SUSTAINABILITY: '/what-we-do/sustainability',
  WORK: '/work',
  WORK_CATEGORY: '/work/discipline/',
  INSIGHT: '/insights',
  INSIGHT_TAG: '/insights/tag/',
  INSIGHT_ARTICLE: '/insights/:slug',
  INSIGHT_BRAND_AND_COMMS: '/insights/tag/brand-and-comms',
  INSIGHT_INVESTOR_ENGAGEMENT: '/insights/tag/investor-engagement',
  INSIGHT_SUSTAINABLE_BUSINESS: '/insights/tag/sustainable-business',
  INSIGHT_NEWS: '/insights/tag/news',
  INSIGHT_EVENTS: '/insights/tag/events',
  JOIN_OUR_CULTURE: '/join/our-culture',
  JOIN_THE_DEAL: '/join/the-deal',
  JOIN_OPPORTUNITIES: '/join/opportunities',
  JOIN_OPPORTUNITIES_DETAILS: '/join/opportunities/details',
  JOB: '/join/opportunities/:slug',
  CONTACT: '/contact',
  CONTACT_LONDON: '/contact#london',
  CONTACT_NEWCASTLE: '/contact#newcastle',
  CASE_STUDY_SINGLE: '/case-study/:slug',
  COOKIE_POLICY: '/cookie-policy',
  PRIVACY_POLICY: '/privacy-policy',
};

export default routes;
